/* eslint-disable prettier/prettier */
export default {
    'START NOW': '立即注册',
    'Already registered, log in now': '已注册,立即登录',
    'RULE DESCRIPTION': '规则说明',
    'HOW TO MAKE MONEY?': '如何赚钱?',
    'The dealer can promote the game, and the promotion of the game revenue generated by the user, the dealer can get a commission, the more users, the greater the revenue.': '经销商可以推广游戏，推广用户产生的游戏收入， 经销商可以拿到佣金，用户越多，收益就越大。 ',
    'How to promote the game?': '如何推广游戏',
    'Apply to become a promoter': '申请成为发起人',
    'Send an invitation link to your friends or target audience.': '向朋友中的目标受众发送促销链接',
    'Click the promotion link to download and install the game': '点击推广链接下载并安装游戏',
    'Referral link': '您的推广链接',
    'Copy': '复制',
    'Sharing': '分享',
    'Facebook': '脸书',
    'More': '更多',
    'REFERRAL BONUS': '推广数据',
    'Total commission': '总收入',
    'Withdraw': '提现',
    'Time': '时间',
    'DATE': '日期',
    'NEW INSTALLATION': '新下载量',
    'ACTIVE DAY': '活跃天数',
    'INCOME': '收入',
    '(Must be installed through the promotion link, cannot be transmitted through sharelt)': '(必须通过促销链接安装,不能通过sharelt传输)',
    'Only valid for Android users, iOS is temporarily not supported': '仅对Android用户有效，暂时不支持iOS用户',
    'Withdrawal instructions': '提现说明',
    'When the accumulated income reaches RS 1000, you can click withdraw. After withdrawing successfully, the accumulated income will be cleared.': '累积收益达到1000卢比，可点击提现，授权成功 后，累积收益清零',
    'There are several first payment methods that need to be provid when withdrawing cash.': '当提取现金时，需要提供几种第一支付方式。',
    'Different payment methods may have different arrival times, usually within 3-7 working days': '不同的付款方式可能会有不同的到达时间，通常 在3-7个工作日内',
    'EMAIL': '邮箱',
    'PHONE NUMBER': '电话号码',
    'CAPTCHA': '验证码',
    'LOG IN': '登录',
    'SIGN UP': '注册',
    'Forgot password ?': '忘记密码?',
    'PASSWORD': '密码',
    'CONFIRM PASSWORD': '确认密码',
    'Log in with already account': '登录已有的账号',
    'Forget Password': '忘记密码',
    'VERIFICATION': '验证',
    'Email retrieval': '邮箱验证',
    'Phone number retrieval': '电话号码验证',
    'NEW PASSWORD': '新密码',
    'CONFIRM NEW PASSWORD': '确认新密码',
    'Log in with message': '用信息登录',
    'Send the code': '发送验证码',
    'invite friends': '赚取高额佣金',
    'The last {0} days': '过去{0}天',
    'Last {0} days': '过去{0}天',
    'Cancel': '取消',
    'Confirm': '确定',
    'Verify code was sent to your phone number': '验证码已经发送到您的手机',
    'Failed to send verify code sms, please check your phone number and retry.': '短信验证码发送失败，请检查您的手机号码然后重试',
    'Your verify code mismatch with phone numer': '您的验证码和手机号码不匹配',
    'This phone numer has been registered!': '您的手机号码已经被注册',
    'Something went wrong with server, please retry later.': '服务器开小差了，请稍后重试',
    'Your phone number or password is incorrect, check and retry.': '您的手机号码或者密码不正确，请检查然后重试',
    'Your password has been changed.': '您的密码已经更改',
    'Your phone number is incorrect, check and retry.': '您的手机号码不正确，请检查然后重试',
    'Login info expires, please login.': '您的登录信息已经过期，请重新登录',
    'Balance is not enough for withdraw. Earning more and try again.': '您的余额还未达到提现标准，请先去赚取更多佣金',
    'Try too many withdraws in 24 hours. Earning more and try again.': '您24小时内提现次数过多，请先去赚取更多佣金',
    'Withdraw service failed temporarily. Earning more and try again.': '提现服务开小差了，请先去赚取更多佣金',
    'Your promotional link copied.': '您的推广链接已经复制',
    'Share failed or canceled': '分享操作失败或者被取消',
    'Something went wrong with sharing.': '分享操作出了一点小问题',
    'Withdraw success and go to cash check.': '提现成功，即将跳转到操作页面',
    'How to earn more money?': '如何赚更多的钱?',
    'Copy the unique URL link on your personal page to others, and all users registered by this link will be your agents.': '你可以复制个人主页的链接给下游，所有访问该链接注册的用户都是你的下游',
    'Type your phone number': '输入您的手机号码',
};
