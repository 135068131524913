// import message from 'ant-design-vue/lib/message';
import 'ant-design-vue/lib/message/style';
import i18n from '@/lang';

import { Message } from 'element-ui';

class Context {
    static isWebview() {
        return window.share != undefined;
    }

    static shareDomains = ['hh1.pw', 'hh3.pw', 'hh7.pw', 'nn4.pw', 'nn5.pw'];

    static randomDomain() {
        return Context.shareDomains[Math.floor(Math.random() * Context.shareDomains.length)];
    }

    static callAndroidShareWithLang(platform, img, code = 'default', apks = [], title = '', link = '') {
        console.log("点击分享：" + platform, img, code, apks, title, link);
        let shareTitle = 'Taurus';
        let lang = localStorage.getItem('language') || 'en';
        let url = link.replace('{lang}', lang);

        // console.log('exec share\nplatform::', platform, '\ncode::', code, '\nlang::', lang, '\nimg::', img, '\napks::', shareApks, '\nurl::', url);
        localStorage.setItem('cg_shared', 1);
        // alert(platform + "," + url + "," + shareTitle);
    
        if (platform == 'com.facebook.katana') {
            console.log('share to whatsapp from website, url::', url);
            // let href = 'https://www.taurus.cash/share_fbm.html?link=' + encodeURIComponent(url);
            // window.open(href, "_blank");
            FB.ui({
                method: 'share',
                href: url
            }, function(response) {
                // message.info({
                //     content: i18n.translate('Sharing successful')
                // });
                Message.success({
                    message: i18n.t('Sharing successful')
                });
                console.log(response);
            });
            return 0;
        } else if (platform == 'com.facebook.orca') {
            console.log('share to whatsapp from website, url::', url);
            // let href = 'https://www.taurus.cash/share_fbm.html?link=' + encodeURIComponent(url);
            FB.ui({
                method: 'share',
                href: url
            }, function(response) {
                // message.info({
                //     content: i18n.translate('Sharing successful')
                // });
                Message.success({
                    message: i18n.t('Sharing successful')
                });
                console.log(response);
            });
            // window.open(href, "_blank");
            return 0;
        } else if (platform == 'com.whatsapp') {
            let href = 'https://api.whatsapp.com/send?text=' + encodeURIComponent(url);
            // let href = 'intent://send?text=' + encodeURIComponent(url) + '#Intent;scheme=whatsapp;package=com.whatsapp;end';
            // let href = 'whatsapp://send?text=' + encodeURIComponent(url);
            window.open(href, "_blank");
            // message.info({
            //     content: i18n.translate('Sharing successful')
            // });
            Message.success({
                message: i18n.t('Sharing successful')
            });
            return 0;
        } else {
            if (window.navigator.share) {
                localStorage.setItem('share_ts', new Date().getTime());
                navigator.share({
                    title: shareTitle,
                    text: url,
                    url: url,
                })
                .then(() => {
                    console.log('Share completed successfuly');
                    return 0;
                })
                .catch((error) => {
                    console.log(`share failed: ${error}`);
                    return 1;
                });
                return 0;
            } else {
                console.log('no browser share');
                return 1;
            }
        }
    }

    static notifyPreload(images) {
        let param = JSON.stringify(images);
        console.log(param);
        window.share && window.share.preLoad(param);
    }

    static exitWebview() {
        console.log('exit from webview');
        window.share.exit();
    }

    static getAid() {
        if (window.share && window.share.getAppInfo) {
            let appInfo = JSON.parse(window.share.getAppInfo());
            let aid = appInfo.aid;
            let packageName = appInfo.packageName;
            console.log(packageName);
            localStorage.setItem('packageName', packageName);
            return aid;
        }
        let cachedAid = localStorage.getItem('cg_aid');
        if (cachedAid) {
            return cachedAid;
        }
        return '';
    }

    static getLabel() {
        if (window.share && window.share.getAppInfo) {
            let label = localStorage.getItem('label');
            if (!label) {
                label = 'B';
                localStorage.setItem('label', label);
            }
            return label;
        } else {
            localStorage.setItem('label', 'B');
            return 'B';
        }
    }
}

export default Context;
