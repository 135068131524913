class Locale {

    static availableLocales = [
        // 'id',
        'en',
        'hi',
    ];

    static defaultLocale = 'en';

    static getLocale() {
        let locale = localStorage.getItem('language') || window.navigator.language || Locale.defaultLocale;
        locale = locale.toLocaleLowerCase().substring(0, 2);
        if (Locale.availableLocales.indexOf(locale) >= 0) {
            return locale;
        }
        return Locale.defaultLocale;
    }

    static changeLocale(locale) {
        if (!locale) {
            locale = Locale.defaultLocale;
        }
        locale = locale.toLowerCase();
        if (Locale.availableLocales.indexOf(locale) == -1) {
            locale = Locale.defaultLocale;
        }
        localStorage.setItem('language', locale);
    }
}

export default Locale;
