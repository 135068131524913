/* eslint-disable prettier/prettier */
export default {
    '{0} invited {1} friends, get {2} Rs. reward': '{0} mengundang {1} teman, dapatkan penghargaan Rp. {2}',
    'HOW TO EARN MONEY?': 'BAGAIMANA CARA DAPATKAN UANG?',
    'What benefit in LV2?': 'Manfaat apa di LV2?',
    'Not only earning from promotion, can earn after getting secondary agents by sharing their bonus. More agents, more commission.': 'Tidak hanya menghasilkan dari promosi, bisa dapatkan setelah perolehan agen sekunder dengan bagikan bonusnya. Semakin banyak agen, semakin banyak komisi.',
    'How to Upgrade to LV2?': 'Bagaimana cara Ditingkatkan ke LV2?',
    'The bar increases along with more new players install and recharge.': 'Bilah meningkat seiring dengan lebih banyak pemain baru menginstal dan mengisi ulang.',
    'If you have channel, please contact us to verify and update to LV2.': 'Mohon hubungi kami untuk memverifikasi dan memperbarui ke LV2 jika Anda memiliki saluran.',
    'LV2 will be verified regularly, and it will be removed if not qualified.': 'LV2 akan diverifikasi secara berkala dan akan dihapus jika tidak memenuhi syarat.',
    'How to develop agents?': 'Bagaimana cara kembangkan agen?',
    'You will get an exclusive link, eg. Yourname.taurus.com and all new players enter website by this link will be your agents.': 'Anda akan dapatkan tautan eksklusif, mis. Yourname.taurus.com dan semua pemain baru yang memasuki situs web melalui tautan ini akan menjadi agen Anda.',
    'How to get agents’ bonus?': 'Bagaimana cara dapatkan bonus agen?',
    'This bonus is from the players invited by your agents recharges for the game. It is provided by our end and will not affect your agents’ income.': 'Bonus ini berasal dari pemain yang diundang oleh agen Anda dengan mengisi ulang game. Bonus ini disediakan oleh pihak kami dan tidak memengaruhi pendapatan agen Anda.',
    'How to earn money?': 'Bagaimana cara dapatkan uang?',
    'How to share this game?': 'BAGAIMANA CARA BERBAGI GAME INI?',
    'Share the referral link to others': 'Bagikan tautan rujukan kepada pihak lain',
    'The invitee must install the game by the link': 'Pihak yang diundang harus menginstal game melalui tautan',
    '(Please install by promotion link not sharelt)': '(Mohon instal dengan tautan promosi bukan bagikan)',
    'Only available in Android users': 'Hanya tersedia di pengguna Android',
    'Get Commissions from:': 'Dapatkan Komisi dari:',
    'Install': 'Install',
    'Recharge in-game': 'Isi ulang dalam game',
    'Referral Link': 'Tautan Referensi',
    'View More': 'Lihat Lebih Banyak',
    'Copy Link': 'Salin tautan',
    'Copy': 'Salin',
    'Share': 'Bagikan',
    'Share your link': 'Bagikan tautan Anda',
    'REFERRAL BONUS': 'BONUS REFERENSI',
    'Total commission': 'Total komisi',
    'Rs': 'Rp',
    '5,834.71(Rs)': '5.834,71 (Rp)',
    '=5,834.71(MY)+0.00(AGENT)': '= 5.834,71 (SAYA) +0,00 (AGEN)',
    'Withdraw': 'Penarikan',
    'Withdrawal instructions': 'Instruksi penarikan',
    'If you would like to withdraw the money you have earned, the minimum withdrawal amount under this Referral Program should be reached RS.1000. Please note that the income amount will be cleared after withdrawing.': 'Jumlah penarikan minimum di bawah Program Referensi ini harus mencapai RP.1000 jika Anda ingin tarik uang yang telah Anda peroleh. Mohon perhatikan bahwa jumlah pendapatan akan dihapus setelah penarikan.',
    'The funds efficiency varies from payment methods, which usually takes 3-7 business days.': 'Efisiensi dana bervariasi dari metode pembayaran, di mana biasanya memerlukan waktu 3-7 hari kerja.',
    'The last {0} days': '{0} hari terakhir',
    'Cancel': 'Batalkan',
    'Confirm': 'Konfirmasi',
    'ALL INCOME': 'SEMUA PENDAPATAN',
    'ALL GAMES': 'SEMUA GAMES',
    'MY': 'SAYA',
    'AGENT': 'AGEN',
    'DATE': 'TANGGAL',
    'NEW INSTALLATION': 'INSTALASI BARU',
    'ACTIVE DAY': 'HARI AKTIF',
    'INCOME': 'PENDAPATAN',
    '2020-10-30': '30-10-2020',
    '35.34': '35.34',
    'Leaderboard': 'Papan Peringkat',
    'Daily': 'Harian',
    'Weekly': 'Mingguan',
    'Monthly': 'Bulanan',
    'RS.': 'RP.',
    'LV0': 'LV0',
    'LV1': 'LV1',
    'LV2': 'LV2',
    'WITHDRAWAL': 'PENARIKAN',
    'PHONE NUMBER': 'Nomor Ponsel',
    'WITHDRAW AMOUNT': 'JUMLAH PENARIKAN',
    'Rs:': 'Rp:',
    'SUBMIT': 'Kirim',
    'Close': 'Tutup',
    'Talk to': 'Berbicara dengan',
    'Please enter the information': 'Silakan masukkan informasinya',
    'Send': 'Kirim',
    'Earn Money': 'Dapatkan uang',
    'RULE DESCRIPTION': 'DESKRIPSI ATURAN',
    'The commission can be attained by simply sharing the game. More users you refer to, the higher revenue you earn!': 'Komisi dapat diperoleh hanya dengan bagikan game. Semakin banyak pengguna yang Anda rujuk, semakin tinggi pendapatan yang Anda peroleh!',
    'Apply to become a promoter': 'Lamar menjadi promotor',
    'Send an invitation link to your friends or target audience.': 'Kirim tautan undangan ke teman atau audiens target Anda.',
    'Click the promotion link to download and install the game': 'Klik tautan promosi untuk mengunduh dan menginstal game ini',
    'GUEST': 'TAMU',
    'Sign Up': 'Daftar',
    'CAPTCHA': 'Captcha',
    'Send the code': 'Kirimkan kode',
    'YOUR NAME': 'Nama Anda',
    'NAME': 'Nama',
    'PASSWORD': 'Kata Sandi',
    'CONFIRM PASSWORD': 'Konfirmasi Kata Sandi',
    'SIGN UP': 'DAFTAR',
    'LOG IN': 'LOGIN',
    'Log in with already account': 'Login dengan akun tersedia',
    'Forgot password ?': 'Lupa kata sandi?',
    'Forget Password': 'Lupa Kata Sandi',
    'VERIFICATION': 'VERIFIKASI',
    'Password confirm mismatch': 'Nomor ponsel salah',
    'Invalid mobile phone number': 'Kata sandi tidak berlaku',
    'Invalid password': 'Nama pengguna tidak berlaku',
    'Username required': 'Nama pengguna memerlukan',
    'Username requires 6-20 characters': 'Nama pengguna memerlukan 6-20 karakter',
    'Password requires 6-20 characters': 'Kata sandi memerlukan 6-20 karakter',
    'Your referral link copied.': 'Link referrensi Anda disalin.',
    'Your promotional link copied.': 'Tautan promosi Anda disalin.',
    'Successful': 'Berhasil',
    'Login info expires, please login.': 'Info login kedaluwarsa, mohon login.',
    'Something went wrong for get share config.': 'Terjadi kesalahan untuk dapatkan konfigurasi bagikan.',
    'Verify code was sent to your phone number': 'Verifikasi kode telah dikirim ke nomor ponsel Anda',
    'Send too many sms, please wait a momnt or use another phone number.': 'Kirim terlalu banyak sms, mohon tunggu sebentar atau gunakan nomor ponsel lain.',
    'This phone number is used. Go to login or use another.': 'Nomor ponsel ini telah digunakan. Pergi untuk login atau gunakan yang lain.',
    'Failed to send verify code sms, please check your phone number and retry.': 'Pengiriman sms kode verifikasi gagal, mohon diperiksa nomor ponsel Anda dan dicoba lagi.',
    'Your verify code mismatch with phone numer': 'Kode verifikasi Anda tidak sesuai dengan nomor ponsel',
    'This phone numer has been registered!': 'Nomor ponsel ini telah terdaftar!',
    'Something went wrong with server, please retry later.': 'Terjadi kesalahan server, mohon dicoba lagi nanti.',
    'Your phone number or password is incorrect, check and retry.': 'Nomor ponsel atau kata sandi Anda salah, mohon diperiksa dan dicoba lagi.',
    'Your password has been changed.': 'Kata sandi Anda telah diubah.',
    'Access denied, please retry': 'Akses ditolak, mohon dicoba lagi',
    'Your phone number is incorrect, check and retry.': 'Nomor ponsel Anda salah, mohon diperiksa dan dicoba lagi.',
    'share failed or canceled': 'pembagian gagal atau dibatalkan',
    'TOP': 'PUNCAK',
    'EARN CASH BONUS!': 'DAPATKAN BONUS KAS!',
    'INVITE FRIENDS': 'MENGUNDANG TEMAN',
    'TeenPatti-Plus': 'TeenPatti-Plus',
    'TeenPatti-Pro': 'TeenPatti-Pro',
    'Facebook': 'Facebook',
    'WhatsApp': 'WhatsApp',
    'Messenger': 'Messenger',
    'How to earn more money?': 'Bagaimana untuk mendapatkan lebih banyak uang?',
    'Copy the unique URL link on your personal page to others, and all users registered by this link will be your agents.': 'Salin tautan URL unik di halaman pribadi Anda ke orang lain, dan semua pengguna yang terdaftar melalui tautan ini akan menjadi agen Anda.',
    'Type your phone number': 'Ketik nomor telepon Anda',
    'Withdraw in progress, check and handle it first.': 'Penarikan sedang dilakukan, periksa dan tangani dulu.',
    'At least {0} can be withdrawn': 'Setidaknya {0} dapat ditarik',
    'At most {0} withdraw in 24 hours': 'Penarikan maksimal {0} dalam 24 jam',
    'Phone number required': 'Nomor telepon diperlukan',
    'Download': 'Unduh',
    'HOT': 'POP',
    'NEW': 'BARU',
    'Double Bonus': 'Hadiah Ganda',

    'Please signup before your withdrawal': 'Harap daftar sebelum penarikan Anda',
    'Withdraw amount': 'Jumlah penarikan',
    'UPI': 'UPI',
    'Requires your UPI ID': 'Membutuhkan ID UPI Anda',
    'UPI ID': 'ID UPI',
    'Bank account': 'Akun bank',
    'Requires account number & IFSC code': 'Memerlukan nomor akun dan kode IFSC',
    'BANK ACCOUNT NUMBER': 'Nomor Rekening Bank',
    'Type your bank account number': 'Ketik nomor rekening bank Anda',
    'CONFIRM BANK ACCOUNT NUMBER': 'Konfirmasi Nomor Rekening Bank',
    'Confirm your bank account number': 'Konfirmasi nomor rekening bank Anda',
    'BANK ACCOUNT HOLDER NAME': 'Nama Pemlik Rekening Bank',
    'Type your bank account holder name': 'Ketik nama pemegang rekening bank Anda',
    'IFSC CODE': 'Kode IFSC',
    'Type your IFSC code': 'Ketik kode IFSC Anda',
    'Please select UPI or Bank account for your withdrawal': 'Pilih UPI atau Rekening bank untuk penarikan Anda',
    'UPI ID required': 'ID UPI diperlukan',
    'Bank account number required': 'Nomor rekening bank diperlukan',
    'Bank account number confirmation does not match': 'Konfirmasi nomor rekening bank tidak cocok',
    'Bank account holder name required': 'Nama pemegang rekening bank diperlukan',
    'Bank account IFSC code required': 'Kode IFSC rekening bank diperlukan',
    "Dont't hava an account?": "Tidak punya akun?",
    'Create': 'Mencipta',

    'Type your password': 'Ketik sandi Anda',
    'Type your captcha': 'Ketik captcha anda',
    'Type your name': 'Ketik nama anda',
    'Confirm your password': 'Konfirmasi sandi Anda',
};
