<style>
* {
    background-repeat: no-repeat;
}

html,
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
}

ul,
li,
p {
    margin: 0;
    padding: 0;
    list-style: none;
}

p {
    hyphens: auto;
}

img {
    display: block;
}

button {
    border: none;
    outline: none;
}

.flex-m {
    display: flex;
    align-items: center;
}



.ell {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

#app {
    line-height: 1;
    font-family: 'PingFang SC', 'STHeitiSC-Light', 'Helvetica-Light', arial, sans-serif, 'Droid Sans Fallback';
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    font-size: rem(32px);
    min-height: 100%;
    /* height: 100%; */


}

/* 处理input type = number的上下箭头 */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
}

input[type='number'] {
    -webkit-appearance: none !important;
    -moz-appearance: textfield !important;
}

.ant-message {
    top: 60% !important;
    font-size: 0.32rem !important;
    width: 80% !important;
    margin: 0 10% !important;
}

.ant-message-notice-content {
    padding: 0.1rem 0.16rem !important;
    background-color: #ddd !important;
}

.ant-message-custom-content {
    font-size: 0.28rem;
    line-height: 0.28rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.ant-message .anticon {
    font-size: 0.24rem !important;
}

.ant-radio-group {
    display: flex !important;
}

input::placeholder {
    font-size: 0.26rem;
    color: #ccc;
}

input::-webkit-input-placeholder {
    font-size: 0.26rem;
    color: #ccc;
    line-height: normal;
    padding-top: 0.02rem;
}

.el-select,
.el-date-editor {
    margin-left: 10px;
}
</style>

<template>
    <div id="app" ref="vueApp" :class="[$i18n.locale]">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive">
                <!-- 这里是会被缓存的视图组件，比如 Home！ -->
            </router-view>
        </keep-alive>

        <router-view v-if="!$route.meta.keepAlive">
            <!-- 这里是不被缓存的视图组件，比如 Edit！ -->
        </router-view>
    </div>
</template>

<script>
export default {
    name: 'App',
    components: {},
    data() {
        return {
        };
    },
    mounted() {
        this.$refs.vueApp.style.disply = 'block';

    },
    created() {
        if (sessionStorage.getItem('store')) {
            this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))));
            console.log('get store - ', JSON.parse(sessionStorage.getItem('store')));
        }
        window.addEventListener('beforeunload', () => {
            sessionStorage.setItem('store', JSON.stringify(this.$store.state));
            console.log('set store - ', JSON.parse(sessionStorage.getItem('store')));
        });
        window.addEventListener(
            'scroll',
            () => {
                try {
                    let scrollTop = document.querySelector('.top').scrollTop + document.documentElement.scrollTop;
                    console.log(scrollTop);
                    if (scrollTop) {
                        if (scrollTop > 540) {
                            this.$store.commit('setIsT', 0)
                        } else {
                            this.$store.commit('setIsT', 1)
                        }
                    } else {
                        this.$store.commit('setIsT', 1)
                    }
                } catch (e) {
                    this.$store.commit('setIsT', 1)
                }
            },
            true
        );

    },
};
</script>
