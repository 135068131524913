class Cookie {
    //改回用之前的方法
    // static setCookie(token, expires) {
    //     var date = new Date();
    //     date.setTime(date.getTime() + expires);
    //     let str = `token=${token};expires=${date.toGMTString()};`;
    //     document.cookie = str;
    // }

    static setCookie(name, value, path = '/') {
        var str = name + '=' + escape(value) + `;path=${path}`;
        var date = new Date();
        date.setTime(date.getTime() + 7 * 24 * 60 * 60 * 1000);

        str += ';expires=' + date.toGMTString();
        document.cookie = str;
    }

    static deleteAllCookies() {
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf('=');
            var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
        }
    }

    static deleteCookie(name) {
        let n= name.trim();
        var cookies = document.cookie.split(';');
        for (var i = 0; i < cookies.length; i++) {
            var cookie = cookies[i];
            var eqPos = cookie.indexOf('=');
            var cur = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
            if (cur.trim() === n) {
                document.cookie = n + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
            }
        }
    }

    //读取cookies
    static getCookie(name) {
        var arr,
            reg = new RegExp('(^| )' + name + '=([^;]*)(;|$)');
        if ((arr = document.cookie.match(reg))) return unescape(arr[2]);
        else return null;
    }
}
export default Cookie;
