import Vue from 'vue';
import App from './App.vue';

//ElementUI
import ElementUI from 'element-ui';

import 'element-ui/lib/theme-chalk/index.css';
// import locale from 'element-ui/lib/locale/lang/en'//英文

Vue.use(ElementUI);
// Vue.use(ElementUI,{locale});

//消息
import { Message } from 'element-ui';
Vue.prototype.$message = Message;

//状态管理
import Vuex from 'vuex';
Vue.use(Vuex);
const store = new Vuex.Store({
    state: {
        user: {},
        packageList: [],
        pcAfterHeaderFlagOne:0,
        isup:0,
        isdown:1,
        isT:true,
        //菜单栏选中
        isHome:true,
        isMyCommission:false,
        isMyAffiliates:false,
        isDiscountsStore:false,
        products: [],
        selectedPn: ''
    },
    mutations: {
        setIsT(state,isT){
            state.isT = isT;
        },
        setUser(state, user) {
            state.user = user;
        },
        setPackage(state, packageList) {
            state.packageList = packageList;
        },
        // 增加项目列表和当前选中的pn
        setProducts(state, products) {
            state.products = products;
        },
        setSelectedPn(state, selectedPn) {
            state.selectedPn = selectedPn;
        },
        changePcAfterHeaderFlagOne(state){
            if( state.pcAfterHeaderFlagOne === 1 ){
                state.pcAfterHeaderFlagOne = 0 ;
            }else{
                state.pcAfterHeaderFlagOne = 1 ;
            }
        },
        setPcAfterHeaderFlagOneToZero(state){
            state.pcAfterHeaderFlagOne = 0 ;
        },
        
        setIsUpToZero(state){
            state.isup = 0 ;
        },
        setIsUpToOne(state){
            state.isup = 1 ;
        },

        setIsDownToZero(state){
            state.isdown = 0 ;
        },

        setIsDownToOne(state){
            state.isdown = 1 ;
        },

        changeHomeBackground(state){
            state.isHome = false ;
            state.isMyCommission = false ;
            state.isMyAffiliates = false ;
            state.isDiscountsStore = false ;

            state.isHome = true ;
        
        },

        changeMyCommissionBackground(state){
            state.isHome = false ;
            state.isMyCommission = false ;
            state.isMyAffiliates = false ;
            state.isDiscountsStore = false ;

            state.isMyCommission = true ;
          
        },

        changeMyAffiliatesBackground(state){
            state.isHome = false ;
            state.isMyCommission = false ;
            state.isMyAffiliates = false ;
            state.isDiscountsStore = false ;

            state.isMyAffiliates = true ;
          
        },
        
        changeDiscountsStoreBackground(state){
            state.isHome = false ;
            state.isMyCommission = false ;
            state.isMyAffiliates = false ;
            state.isDiscountsStore = false ;

            state.isDiscountsStore = true ;
      
        }


        
    }
});

import Cookie from '@/utils/cookie';

//路由
import VueRouter from 'vue-router';
Vue.use(VueRouter);
const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/index',
            name: 'index',
            meta: {
                // keepAlive: true // 需要被缓存
                index: '1'
            },
            component: () => import('./pages/index')
        },
        {
            path: '/',
            name: 'index',
            component: () => import('./pages/index'),
            meta: {
                // keepAlive: true // 需要被缓存
                index: '1'
            }
        },
        {
            path: '/index.html',
            name: 'index',
            component: () => import('./pages/index'),
            meta: {
                // keepAlive: true // 需要被缓存
                index: '1'
            }
        },
        {
            path: '/pcSignin',
            name: 'pcSignin',
            meta: {
                // keepAlive: true // 需要被缓存

            },
            component: () => import('./pages/PC-sign-in.vue')
        },
      /*   {
            path: '/rank',
            meta: {
                // keepAlive: true, // 不需要被缓存，
                // requireAuth: true,
            },
            component: () => import('./pages/rank')
        }, */
        {
            path: '/pcHow',
            name: 'pcHow',
            meta: {
                // keepAlive: true // 需要被缓存
                index: '2'
            },
            component: () => import('./pages/PC-how-it-works')
        },
        {
            path: '/pcVision',
            name: 'pcVision',
            meta: {
                // keepAlive: true // 需要被缓存
                index: '3'
            },
            component: () => import('./pages/PC-vision.vue')
        },
        {
            path: '/pcStory',
            name: 'pcStory',
            meta: {
                // keepAlive: true // 需要被缓存
                index: '4'
            },
            component: () => import('./pages/PC-Story.vue')
        },
        {
            path: '/pcRanking',
            name: 'pcRanking',
            meta: {
                // keepAlive: true // 需要被缓存
                index: '5'
            },
            component: () => import('./pages/PC-Ranking.vue')
        },

        {
            path: '/pcContact',
            name: 'pcContact',
            meta: {
                // keepAlive: true // 需要被缓存
                index: '6'
            },
            component: () => import('./pages/PC-contact.vue')
        },

        {
            path: '/pcTermsOfUse',
            name: 'pcTermsOfUse',
            meta: {
                // keepAlive: true // 需要被缓存
            },
            component: () => import('./pages/PC-terms-of-use.vue')
        },
        {
            path: '/pcPrivacyPolicy',
            name: 'pcPrivacyPolicy',
            meta: {
                // keepAlive: true // 需要被缓存
            },
            component: () => import('./pages/PC-privacy-policy.vue')
        },
        {
            path: '/pcFaq',
            name: 'pcFaq',
            meta: {
                // keepAlive: true // 需要被缓存
            },
            component: () => import('./pages/PC-faq.vue')
        },
        {
            path: '/pcAppDownload',
            name: 'pcAppDownload',
            meta: {
                // keepAlive: true // 需要被缓存
            },
            component: () => import('./pages/PC-app-download.vue')
        },
        {
            path: '/home',
            name: 'home',
            component: () => import('./pages/home'),
            meta: {
                keepAlive: false, // 不需要被缓存，
                requireAuth: true,
            },
        },
        /* 
        {
            path: '/withdraw',
            component: () => import('./pages/withdraw'),
            meta: {
                keepAlive: false, // 不需要被缓存，
                requireAuth: true,
            }
        }, */
        {
            path: '/pcDiscountsStore',
            meta: { requiresAuth: true }, 
            name:'pcDiscountsStore',
            component: () => import('./pages/PC-discounts-store.vue')
        },

        {
            path: '/pcMyCommission',
            name:'pcMyCommission',
            meta: { requiresAuth: true }, 
            component: () => import('./pages/PC-my-commission.vue')
        },

        {
            path: '/pcMyAffilialtesCommission',
            meta: { requiresAuth: true }, 
            component: () => import('./pages/PC-my-affilialtes-commission.vue')
        },

        {
            path: '/pcMyAffiliates',
            name: 'pcMyAffiliates',
            meta: { requiresAuth: true }, 
            component: () => import('./pages/PC-my-affiliates.vue')
        },
        {
            path: '/pcSend',
            name: 'pcSend',
            meta: { requiresAuth: true }, 
            component: () => import('./pages/PC-send.vue')
        },
        {
            path: '/pcSendHistory',
            name: 'pcSendHistory',
            meta: { requiresAuth: true }, 
            component: () => import('./pages/PC-send-history.vue')
        },
        {
            path: '/pcPurchaseHistory',
            name: 'pcPurchaseHistory',
            meta: { requiresAuth: true }, 
            component: () => import('./pages/PC-purchase-history.vue')
        },
        {
            path: '/pcWithdrawHistory',
            name: 'pcWithdrawHistory',
            meta: { requiresAuth: true }, 
            component: () => import('./pages/PC-withdraw-history.vue')
        },
        {
            path: '/PcStoryDetail/:id',
            name: 'PcStoryDetail',
            meta: {
                //  requiresAuth: true
            }, 
            component: () => import('./pages/PC-Story-Detail.vue')
        },
        //该路由放最后，用于拦截其他路由跳转到首页
       /*  {
            path: '*',
            redirect: '/'
        } */
    ]
});

  router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        let token = Cookie.getCookie('token');
        if (!token) { // 判断当前的token是否存在
            next({
                path: '/',
                // query: { redirect: to.fullPath } // 是否带上跳转前的url
            })
        } else {
            next()
        }
    } else {
        next() // 确保一定要调用 next()
    }
  })


import VueClipboard from 'vue-clipboard2';

Vue.use(VueClipboard);

import i18n from '@/lang';

import logger from '@/utils/logger';

Vue.prototype.$log = logger;

Vue.config.productionTip = false;

Vue.filter('formatDate', d => {
    d = '' + d;
    if (d.length == 8) {
        return d.substring(0, 4) + '-' + d.substring(4, 6) + '-' + d.substring(6, 8);
    }
    return d;
});

Vue.filter('formatNum', d => {
    return (d || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
});

Vue.filter('formatPrice', d => {
    d = (d || 0).toFixed(2);
    let nums = d.split('.', 2);
    // return nums[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + '.' + nums[1];
    return nums[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
});

import message from 'ant-design-vue/lib/message';
message.config({
    duration: 3,
    maxCount: 1
});

import { Radio } from 'ant-design-vue';
// import 'ant-design-vue/lib/radio/style/index.css';
import '@/assets/css/antv-radio.css';
Vue.use(Radio);

import { Carousel, CarouselItem } from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.component(Carousel.name, Carousel);
Vue.use(CarouselItem);

import VueLazyload from 'vue-lazyload';

Vue.use(VueLazyload, {
    attempt: 1
});

new Vue({
    store,
    router,
    i18n,
    render: h => h(App)
}).$mount('#app');
