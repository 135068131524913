// import { production } from '../config';
import Cookie from '@/utils/cookie';
import Context from '@/utils/context';

class Logger {
    error() {}

    static keyNames = {
        'tw_home_display':'首页展示',
        'tw_home_login':'点击登陆',
        'tw_home_login_suc':'登陆成功',
        'tw_home_reg_send_otp':'点击发送注册验证码',
        'tw_home_reg_send_otp_suc':'注册验证码发送成功',
        'tw_home_reg_confirm':'点击确认注册',
        'tw_home_reg_suc':'注册成功',
        'tw_home_language':'首页-点击多语言',
        'tw_home_rule':'首页-点击规则',
        'tw_home_customer':'首页-点击客服',
        'tw_home_vision':'首页-点击愿景',
        'tw_home_rank':'首页-点击排行榜',
        'tw_home_contactus':'首页-点击联系我们',
        'tw_home_term':'首页-点击用户条款',
        'tw_home_policy':'首页-点击隐私协议',
        'tw_home_faq':'首页-点击FAQ',
        
        'tw_home_login_commission':'首页-点击收入',
        'tw_home_login_affiliate':'首页-点击代理',
        'tw_home_login_store':'首页-点击商城',
        'tw_home_login_withdraw':'首页-点击提现',
        'tw_home_login_withdraw_his':'首页-点击提现记录',
        'tw_home_login_level':'首页-点击等级提示',
        'tw_home_login_share_FB':'首页-点击分享-FB',
        'tw_home_login_share_WA':'首页-点击分享-WA',
        'tw_home_login_share_COPY':'首页-点击分享-复制链接',
        'tw_home_login_share_download':'首页-点击分享-下载APP',
        'tw_home_login_copy_affiliate':'首页-复制代理连接',
        'tw_home_login_welcome':'首页-新手奖励弹窗展示',
        'tw_home_login_welcome_confirm':'首页-新手奖励-点击领取',
        'tw_home_login_menu':'首页-点击菜单',
        'tw_home_login_out':'首页-点击退出登陆',
        
        'tw_recharge_display':'充值页展示',
        'tw_recharge_trade':'充值页-点击赠送',
        'tw_recharge_buy':'充值页-点击购买',
        'tw_recharge_buy_suc':'充值页-购买成功',
        'tw_recharge_buy_fail':'充值页-购买失败',
        'tw_recharge_history':'充值页-购买历史',
        
        'tw_trade_display':'赠送页展示',
        'tw_trade_history':'赠送页-赠送历史',
        'tw_trade_send_refer':'赠送页-点击赠送给推广用户',
        'tw_trade_send_uid':'赠送页-点击赠送给指定用户',
        'tw_trade_send_confirm':'赠送页-确定赠送',
        'tw_trade_suc':'赠送页-赠送成功',
        'tw_trade_fail':'赠送页-赠送失败',
        'tw_home_click_figure':'点击大咖说轮播内容',
        'tw_home_click_dowtaurus':'点击下载taurus',
        'tw_home_click_story':'菜单栏-点击大咖说菜单',
        'tw_story_click_figure':'点击列表大咖说',
        'tw_story_turnpages':'点击翻页',
        'tw_story_detail_goDetails':'进入大咖说详情页面',        
    };

    static getReferrer() {
        let origin = localStorage.getItem('cg_origin');
        if (origin) {
            return `广告推广_${origin}`;
        }
        let xRequestWith = Cookie.getCookie('x_request_with');
        if (xRequestWith) {
            if (Context.isWebview()) {
                return `游戏内进入_${xRequestWith}`;
            } else {
                return `其他应用_${xRequestWith}`;
            }
        }
        let referrer = localStorage.getItem('cg_referrer') || document.referrer;
        if (!referrer) {
            return '直接打开';
        } else if (referrer.indexOf('.google.') >= 0 || referrer.indexOf('//google.') >= 0) {
            return '谷歌搜索';
        } else if (referrer.indexOf('bing.com') >= 0) {
            return 'bing搜索';
        } else {
            return '其他_'+ referrer;
        }
    }

    static getName(key) {
        // return key + '_' + (Logger.keyNames[key] || '');
        return key
    }

    info(key, value) {
        let v = Object.assign({}, value||{})

        // v['来源'] = Logger.getReferrer();
        console.log('try to report event:', Logger.getName(key), v);
        // if (production && analytics) {
        if (analytics) {
            analytics.logEvent(Logger.getName(key), v);
            return;
        }
    }
}

export default new Logger();
