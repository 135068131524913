import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from './en';
import zhLocale from './zh';
import hiLocale from './hindi';
import idLocale from './indonesian';
import Locale from '@/utils/locale';
import ElementUI from 'element-ui';
import enELlocale from 'element-ui/lib/locale/lang/en' // lang i18n elementui语言包(英文包)
// import idELlocale from 'element-ui/lib/locale/lang/id' // lang i18n elementui语言包(英文包)
const messages = {
    en: {
        ...enLocale,
        ...enELlocale
    },
    zh: {
        ...zhLocale
    },
    hi: {
        ...hiLocale
    },
    id: {
        ...idLocale,
    }
};

class I18n extends VueI18n {
    constructor(options) {
        super(options);
    }
}

Vue.use(I18n);

const i18n = new I18n({
    locale: 'en',
    messages
});

Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
  })

i18n.locale = Locale.getLocale();

const translate = key => {
    if (!key) {
        return '';
    }
    return i18n.t(key);
};
i18n.translate = translate;

export default i18n;
