/* eslint-disable prettier/prettier */
export default {
    '{0} invited {1} friends, get {2} Rs. reward': '{0} invited {1} friends, got {2} Rs. commission',
    'START NOW': 'START NOW',
    'Already registered, log in now': 'Already registered, log in now',
    'RULE DESCRIPTION': 'RULE DESCRIPTION',
    'HOW TO MAKE MONEY?': 'HOW TO MAKE MONEY?',
    'The dealer can promote the game, and the promotion of the game revenue generated by the user, the dealer can get a commission, the more users, the greater the revenue.': 'The dealer can promote the game, and the promotion of the game revenue generated by the user, the dealer can get a commission, the more users, the greater the revenue.',
    'How to promote the game?': 'HOW TO SHARE THIS GAME?',
    'Apply to become a promoter': 'Apply to become a promoter',
    'Send an invitation link to your friends or target audience.': 'Send an invitation link to your friends or target audience.',
    'Click the promotion link to download and install the game': 'Click the promotion link to download and install the game',
    'Referral Link': 'Referral Link',
    'Copy': 'Copy',
    'Share your link': 'Share your link',
    'More': 'More',
    'REFERRAL BONUS': 'REFERRAL BONUS',
    'Rs': '₹',
    'MY': 'MY',
    'AGENT': 'AGENT',
    'Total commission': 'Total commission',
    'Time': 'Time',
    'DATE': 'DATE',
    'NEW INSTALLATION': 'NEW INSTALLATION',
    'ACTIVE DAY': 'ACTIVE DAY',
    'INCOME': 'INCOME',
    '(Must be installed through the promotion link, cannot be transmitted through sharelt)': '(Must be installed through the promotion link, cannot be transmitted through sharelt)',
    'Only valid for Android users, iOS is temporarily not supported': 'Only valid for Android users, iOS is temporarily not supported',
    'Withdrawal instructions': 'Withdrawal instructions',
    'If you would like to withdraw the money you have earned, the minimum withdrawal amount under this Referral Program should be reached RS.1000. Please note that the income amount will be cleared after withdrawing.': 'If you would like to withdraw the money you have earned, the minimum withdrawal amount under this Referral Program should be reached RS.1000. Please note that the income amount will be cleared after withdrawing.',
    'There are several first payment methods that need to be provid when withdrawing cash.': 'There are several first payment methods that need to be provid when withdrawing cash.',
    'The funds efficiency varies from payment methods, which usually takes 3-7 business days.': 'The funds efficiency varies from payment methods, which usually takes 3-7 business days.',
    'EMAIL': 'EMAIL',
    'PHONE NUMBER': 'Phone Number',
    'CAPTCHA': 'Captcha',
    'LOG IN': 'LOG IN',
    'SIGN UP': 'SIGN UP',
    'Forgot password ?': 'Forgot password ?',
    'PASSWORD': 'Password',
    'CONFIRM PASSWORD': 'Confirm Password',
    'Log in with already account': 'Log in with already account',
    'Forget Password': 'Forget Password',
    'VERIFICATION': 'VERIFICATION',
    'Email retrieval': 'Email retrieval',
    'Phone number retrieval': 'Phone number retrieval',
    'NEW PASSWORD': 'New password',
    'CONFIRM NEW PASSWORD': 'Confirm New password',
    'Log in with message': 'Log in with message',
    'Send the code': 'Send the code',
    'invite friends': 'invite friends',
    'The last {0} days': 'The last {0} days',
    'Last {0} days': 'Last {0} days',


    'Verify code was sent to your phone number': 'OTP was sent to you on your mobile phone',
    'Failed to send verify code sms, please check your phone number and retry.': 'Failed to send verify code sms, please check your phone number and retry.',
    'Your verify code mismatch with phone numer': 'Your verify code mismatch with phone numer',
    'This phone numer has been registered!': 'This phone numer has been registered!',
    'Something went wrong with server, please retry later.': 'Something went wrong with server, please retry later.',
    'Your phone number or password is incorrect, check and retry.': 'Your phone number or password is incorrect, check and retry.',
    'Your password has been changed.': 'Your password has been changed.',
    'Your phone number is incorrect, check and retry.': 'Your phone number is incorrect, check and retry.',
    'Login info expires, please login.': 'Login info expires, please login.',
    'Balance is not enough for withdraw. Earning more and try again.': 'Balance is not enough for withdraw. Earning more and try again.',
    'Try too many withdraws in 24 hours. Earning more and try again.': 'Try too many withdraws in 24 hours. Earning more and try again.',
    'Withdraw service failed temporarily. Earning more and try again.': 'Withdraw service failed temporarily. Earning more and try again.',
    'Your promotional link copied.': 'Referral link copied.',
    'Your referral link copied.': 'Referral link copied.',
    'Share failed or canceled': 'Share failed or canceled',
    'Something went wrong with sharing.': 'Something went wrong with sharing.',
    'Withdraw success and go to cash check.': 'Withdraw success and go to cash check.',
    'Install': 'Install',
    'Recharge in-game': 'Recharge in-game',
    'Double': 'Double',
    'WhatsApp': 'WhatsApp',
    'Facebook': 'Facebook',
    'Messenger': 'Messenger',
    'How to earn more money?': 'How to earn more money?',
    'Copy the unique URL link on your personal page to others, and all users registered by this link will be your agents.': 'Copy the unique URL link on your personal page to others, and all users registered by this link will be your agents.',
    'Type your phone number': 'Type your phone number',
    'At least {0} can be withdrawn': 'At least {0} can be withdrawn',
    'Withdraw in progress, check and handle it first.': 'Withdraw in progress, check and handle it first.',
    'At most {0} withdraw in 24 hours.': 'At most {0} withdraw in 24 hours.',
    'Invalid mobile phone number': 'Invalid phone number',
    'Phone number required': 'Phone number required',
    'Download': 'Download',
    "Dont't hava an account?": "Dont't hava an account?",
    'Create': 'Create',

    'Send': 'Send',
    'NAME': 'Name',
    'Bank Account': 'Bank Account',
    'Bank account': 'Bank account',
    'UPI ID': 'UPI ID', //PC-home.vue
    'BANK ACCOUNT NUMBER': 'Bank Account Number',
    'CONFIRM BANK ACCOUNT NUMBER': 'Confirm Bank Account Number',
    'BANK ACCOUNT HOLDER NAME': 'Bank Account Holder Name',
    'IFSC CODE': 'IFSC Code',  
    'Type your password': 'Type your password',
    'Type your captcha': 'Type your captcha',
    'Type your name': 'Type your name',
    'Confirm your password': 'Confirm your password',
    'The system is upgrading, please try later.': 'The system is upgrading, please try later.',

    //新的翻译版本
    //PC-before-head
    'Home':'Home',  //PC-Sign-in.vue PC-After-nav.vue
    'How It Works':'How It Works',//PC-Sign-in.vue
    'App Download':'App Download',//PC-Sign-in.vue
    'Vision':'Vision',//PC-Sign-in.vue
    'Ranking':'Ranking',  //PC-Ranking.vue
    'Contact':'Contact',//PC-Sign-in.vue
    'Sign in':'Sign in',


    
    //index.vue

    'Sign up for':'Sign up for',
    'Free':'Free',
    'Name':'Name',
    'Phone Number':'Phone Number', //PC-sign-in.vue PC-Home.vue
    'Type OTP':'Type OTP',
    'Request':'Request',
    'I agree to the Terms of Use and Privacy Policy':'I agree to the Terms of Use and Privacy Policy',
    'Join Now':'Join Now',
    'Instant Withdrawal':'Instant Withdrawal',
    'Instant Money Transfer':'Instant Money Transfer',
    'After withdrawal':'After withdrawal',
    'the money comes to your pocket quickly':'the money comes to your pocket quickly',
    'Join now and withdraw commission today':'Join now and withdraw commission today',
    'High Commission':'High Commission',
    'High Rewards':'High Rewards',
    'You get unlimited commission':'You get unlimited commission',
    'because commission will be sent to you after every time the invited friends recharge in the game':'because commission will be sent to you after every time the invited friends recharge in the game',
    'Multi-level system of Affiliates':'Multi-level system of Affiliates',
    'High-percentage Commission':'High-percentage Commission',
    'Share Taurus to your friends and invite them to be your affiliates':'Share Invite cash to your friends and invite them to be your affiliates',
    'Then you can get up to 30% commission from their income':'Then you can get up to 30% commission from their income',
    
    //Trumpet.vue
    'Notice':'Notice',

    //CustomerButton.vue
    'Customer Service':'Customer Service',

    //PC-Before-footer.vue
    'Terms of Use':'Terms of Use',
    'Privacy Policy':'Privacy Policy',
    'FAQs':'FAQs',
    'Copyright © 2012-2072 Taurus':'Copyright © 2012-2072 Taurus',
    'All Rights Reserved':'All Rights Reserved',

    //PC-how-it-works.vue
    'Contents':'Contents',
    'How to earn commission':'How to earn commission',
    'How to invite your friends':'How to invite your friends',
    "What's the purpose of divisions":"What's the purpose of divisions",
    'How to upgrade your division':'How to upgrade your division',
    'How to develop your referrals into affiliates':'How to develop your referrals into affiliates',
    'How to get commission from your affiliates':'How to get commission from your affiliates',
    'Four ways to earn huge amount of commission in Taurus':'Four ways to earn huge amount of commission in Taurus',
    'Invite your friends to download the App':'Invite your friends to download the Apps',
    'you can get commission from invitation':'you can get commission from invitation',
    'Normally it’s ₹20 for per person':'Normally it’s ₹20 for per person',

    'You can get commission from recharging,up to 30%,of every recharge of your':'You can get commission from recharging,up to 30%,of every recharge of your',
    'referrals':'referrals',
    'References':'References',
    '.The commission is valid forever. The higher':'.The commission is valid forever. The higher',
    'division':'division',
    'you are, the higher percentage of commission you will get.':'you are, the higher percentage of commission you will get.',
    
    'affiliates':'affiliates',
    'You can also get commission from':'You can also get commission from',
    "which means you can earn commission from your affiliates' referrals;The higher division you are, the more commission you will get.":"which means you can earn commission from your affiliates' referrals;The higher division you are, the more commission you will get.",

    'When you reach silver division,you can buy discounted chips in discounts store and trade with your referrals.So you can gain profits,up to 20%,due to the price difference.The higher division you are,the higher discount rate you will get.':'When you reach silver division,you can buy discounted chips in discounts store and trade with your referrals.So you can gain profits,up to 20%,due to the price difference.The higher division you are,the higher discount rate you will get.',

    'Share the game via social media or share the referral link to your friends.':'Share the game via social media or share the referral link to your friends.',
    'You get commission after your friends click the promotional link, download and install the game.':'You get commission after your friends click the promotional link, download and install the game.',
    'The installation must be finished by the referral link. Sharing the App package by Sharelt etc is not allowed.':'The installation must be finished by the referral link. Sharing the App package by Sharelt etc is not allowed.',
    'The App is available for Android users. The App for IOS is coming soon.':'The App is available for Android users. The App for IOS is coming soon.',
    
    "All promoters are divided into five divisions from the lowest to the highest : bronze division, silver division, gold division, platinum division and diamond division.":"All promoters are divided into five divisions from the lowest to the highest : bronze division, silver division, gold division, platinum division and diamond division.",
    "The higher division you are, the more ways of earning commission you will get.":"The higher division you are, the more ways of earning commission you will get.",
    "The higher division you are, the higher percentage you will get in terms of commission from recharging.The higher division you are, the more commission from affiliates you will get.":"The higher division you are, the higher percentage you will get in terms of commission from recharging.The higher division you are, the more commission from affiliates you will get.",

    'Your get higher division if more referrals you bring to the game and the more they recharge.':'Your get higher division if more referrals you bring to the game and the more they recharge.',
    'If you have extraordinary promotional ability with channels, please get contact with our customer service center to verify your division.':'If you have extraordinary promotional ability with channels, please get contact with our customer service center to verify your division.',
    'We will verify the quality of our promoters regularly and those unqualified promoters will be degraded.':'We will verify the quality of our promoters regularly and those unqualified promoters will be degraded.',

    'Sign in Taurus website, and share your referral link on the homepage to your friend.Guide them to register from the link, then they become your affiliates.':'Sign in Taurus website, and share your referral link on the homepage to your friend.Guide them to register from the link, then they become your affiliates.',
    'Encourage your referrals to invite their friends to play the game, then your referrals will become your affiliates.':'Encourage your referrals to invite their friends to play the game, then your referrals will become your affiliates.',
    'Share more promotion ways to your affiliates, so they can develop their referrals quickly and your commission will increase too.':'Share more promotion ways to your affiliates, so they can develop their referrals quickly and your commission will increase too.',
    'You will get a percentage of commission from your affiliates’ commission.':'You will get a percentage of commission from your affiliates’ commission.',
    'The commission you get from your affiliates is paid by our financial account and your affiliates’ commission won’t be affected.':'The commission you get from your affiliates is paid by our financial account and your affiliates’ commission won’t be affected.',
    '[1] Referral：the friend who you refer the game to':'[1] Referral：the friend who you refer the game to',
    '[2] Division: the status of promoter. Five divisions from the lowest to the highest: bronze, silver , gold , platinum and diamond.':'[2] Division: the status of promoter. Five divisions from the lowest to the highest: bronze, silver , gold , platinum and diamond.',
    '[3] Affiliate: your referral who has shared the game to his friends':'[3] Affiliate: your referral who has shared the game to his friends',

   


     //PC-vision.vue
     'We aim at building a transparent and rules guaranteed platform for you to earn cash money online.':'We aim at building a transparent and rules guaranteed platform for you to earn cash money online.',
     'As game promoters, you can earn a huge amount of commission stably at your leisure here. As our clients, you will find target game promoters cost - effectively. We want to create a more efficient direct marketing system and provide more job opportunities.':'As game promoters, you can earn a huge amount of commission stably at your leisure here. As our clients, you will find target game promoters cost - effectively. We want to create a more efficient direct marketing system and provide more job opportunities.',


     //PC-Ranking.vue
     'Daily Ranking':'Daily Ranking',
     'Weekly Ranking':'Weekly Ranking',
     'Monthly Ranking':'Monthly Ranking',

     //PC-contact.vue
     'Taurus':'Invite Cash',
     
     'E-mail':'E-mail',

     //PC-sign-in.vue
     'Keep me signed in':'Keep me signed in',
     "Don't have an account? Create one now!":"Don't have an account? Create one now!",



     //PC-home.vue
     "Platinum":"Platinum",
    "Bronze":"Bronze",
    "Silver":"Silver",
     "share failed or canceled":"share failed or canceled",
     "Related information cannot be empty":"Related information cannot be empty",
     "The two accounts didn't match":"The two accounts didn't match",
    'UPI ID required':'UPI ID required',
    'Yesterday':'Yesterday',
    'Today':'Today',
    'Account Number':'Account Number',
    'Gold':'Gold',
    'Diamond':'Diamond',
    "Today's Commission":"Today's Commission",
    'Cumulative income':'Cumulative income',
    'Current Balance':'Current Balance',
    'Withdrawal History':'Withdrawal History',
    'Withdraw':'Withdraw',
    "Get Commission from invitation, XX Rupees per person recharge in the game, up to 30%":"Get Commission from invitation, XX Rupees per person recharge in the game, up to 30%",
    "My Referrals":"My referrals", //PC-After-nav.vue
    "My Affiliates":"My Affiliates", 
    "Referral link":"Referral link", 
    "Date":"Date", //PC-my-commission.vue
    "New Referrals":"New Referrals",
    "Active Referrals":"Active Referrals", //PC-my-commission.vue
    "Added Agents":"Added Agents",
    "Commission from Referrals":"Commission from Referrals",	
    "View more":"View more",
    "Download App":"Download App",
    "Division":"Division",

    'Withdrawlal Amount':'Withdrawlal Amount',
    "Withdrawlal Method":"Withdrawlal Method",

    'Account Holder Name':'Account Holder Name',
    'Account Name':'Account Name',
    'Confirm Account Number':'Confirm Account Number', 

    'IFSC Code': 'IFSC Code',

    //PC-After-nav.vue
    'My Commission':'My Commission', //PC-my-commission.vue
    'Discounts Store':'Discounts Store',
    "Referrals":"Referrals",

    "Retention Rate":'Retention Rate',
    "the Second Day Retention Rate":"the Second Day Retention Rate",
    "Affiliats":"Affiliats",
    "Affiliates":"Affiliates",
    "Invitation":"Invitation",	
    "Recharging":"Recharging",
    "Commission from Affiliates":"Commission from Affiliates",
    "Total Commission":"Total Commission", //PC-my-referrals.vue
    "Next":"Next",//PC-my-referrals.vue
    "The last 7 days":"The last 7 days",
    "Commission from Recharging":"Commission from Recharging",
    "Commission from Invitation":"Commission from Invitation",

    //PC-my-referrals.vue
    "Today's Referrals":"Today's Referrals",
    "Today's Active Referrals":"Today's Active Referrals",
    "My Commission Today":"My Commission Today",
    "My Total Commission":"My TotalCommission",
    "Search":"Search",

    //PC-discounts-store.vue
    "Current Chips":"Current Chips",
    "Purchased Chips in Total":"Purchased Chips in Total",
    "Traded Chips with Referral in Total":"Traded Chips with Referral in Total",
    "Tips: Trade with Your Referrals":"Tips: Trade with Your Referrals",

    "Rules":"Rules",
    "You can buy discounted chips, and earn money by price difference when trading with game players. For example, you spend ₹10000 buying ₹10500 chips, and then you trade the chips with game player at a price of ₹10200. So you earn ₹200 and the game player saves ₹300.":"You can buy discounted chips, and earn money by price difference when trading with game players. For example, you spend ₹10000 buying ₹10500 chips, and then you trade the chips with game player at a price of ₹10200. So you earn ₹200 and the game player saves ₹300.",
    "Click the upper button Trade, to recharge the chips instantly to the account of corresponding game player. The chips can only be sent to your referrals.":"Click the upper button Trade, to recharge the chips instantly to the account of corresponding game player. The chips can only be sent to your referrals.",
    "Share or copy the link to your friends. When they download and install the game, they become your referrals.":"Share or copy the link to your friends. When they download and install the game, they become your referrals.",
    "The higher division you are, the higher discount rate you can get. Your division can be upgraded quickly by encouraging your referrals to recharge in the game.":"The higher division you are, the higher discount rate you can get. Your division can be upgraded quickly by encouraging your referrals to recharge in the game.",


    "Purchase History":"Purchase History",

    //PC-send.vue
    "My Chips":"My Chips",
    "send to a specific user":"send to a specific user",	
    "recharge history":"recharge history",
    "Last time":"Last time",
    "User ID":"User ID",

    "My Recharge":"My Recharge",
    "Trade History":"Trade History",
    "User Name":"User Name",
    "Sign Out":"Sign Out",
    "Amount":"Amount",
    "Status":"Status",	
    "Processing":"Processing",
    "Succeeded":"Succeeded",
    "Failed":"Failed",

    "Recharge":"Recharge",
    "Cancel":"Cancel",
    "Confirm":"Confirm",

    "Sign out":"Sign out",
    "Are you sure to sign out of Taurus?":"Are you sure to sign out of Taurus?",

    //
    "Sublevel":"Sublevel",
    "Total Referrals": "Total Referrals",
    "Diamond Division":"Diamond Division",
    "Silver Division":"Silver Division",
    "Gold Division":"Gold Division",
    "Platinum Division":"Platinum Division",
    "When you reach the diamond Division,":"When you reach the diamond Division,",
    "When you reach the Silver level,":"When you reach the Silver level,",
    "When you reach the Gold level,":"When you reach the Gold level,",
    "When you reach the Platinum level,":"When you reach the Platinum level,",
    "you can unblock it.":"you can unblock it.",
	"Affiliates Name":"Affiliates Name",
	"Registration Date":"Registration Date",
    "Last Sign Date":"Last Sign Date",


    //PC-faqs.vue
    "Is there a limit to how much money I can earn from game promotion?":"Is there a limit to how much money I can earn from game promotion?",
    "No, there is no limit to the money you can earn from game promotion. Taurus aims at building a transparent and rules guaranteed platform to earn cash money online.":"No, there is no limit to the money you can earn from game promotion. Taurus aims at building a transparent and rules guaranteed platform to earn cash money online.",

    "How to earn commission?":"How to earn commission?",
    "Share your referral link to others. If they download and install the game, you can get commission.":"Share your referral link to others. If they download and install the game, you can get commission.",
    "If they play and recharge in the game, you can get more commission.":"If they play and recharge in the game, you can get more commission.",
    "Develop your affiliates. You can get a share of the money earned by your affiliates.":"Develop your affiliates. You can get a share of the money earned by your affiliates.",
    "Sell discounted chips to your referrals and make profits by the price difference.":"Sell discounted chips to your referrals and make profits by the price difference.",

    "Why does my commission increase slowly?":"Why does my commission increase slowly?",
    "If your commission increases slowly, there may be several reasons.":"If your commission increases slowly, there may be several reasons.",
    "The number of your referrals increases slowly.":"The number of your referrals increases slowly.",
    "Your referrals are not active and playing in the game.":"Your referrals are not active and playing in the game.",
    "Normally the second reason is the main reason.":"Normally the second reason is the main reason.",

    "How long does it take to withdraw the money?":"How long does it take to withdraw the money?",
    "Normally you can receive the money within seven working days, because after you start withdrawal, Taurus needs to verify the procedure. Besides, it also takes time for the bank to finish the money transferring.":"Normally you can receive the money within seven working days, because after you start withdrawal, Taurus needs to verify the procedure. Besides, it also takes time for the bank to finish the money transferring.",

    "What is the minimum withdrawal amount?":"What is the minimum withdrawal amount?",
    "The minimum withdrawal amount is 1000Rs.":"The minimum withdrawal amount is 1000Rs.",


 
    "Privacy Plice":"Privacy Plice",


    //PC-privacy-policy.vue
    "This privacy policy forms part of our Website's Terms of Service, by accepting the Terms of Service and the Privacy Policy on the sign-up page, you consent to provide sensitive personal data or personal information and are aware of the purpose of sharing such information. Please do not sign in if you do not wish to share the mandatory personal information with Taurus Limited (hereinafter referred as “Taurus” or “we” or “us” “our”) requested at the time of registration. Registration procedure cannot be completed until information in the non-optional fields is provided. Certain additional personal information may be requested by Taurus.":
    "This privacy policy forms part of our Website's Terms of Service, by accepting the Terms of Service and the Privacy Policy on the sign-up page, you consent to provide sensitive personal data or personal information and are aware of the purpose of sharing such information. Please do not sign in if you do not wish to share the mandatory personal information with Taurus Limited (hereinafter referred as “Taurus” or “we” or “us” “our”) requested at the time of registration. Registration procedure cannot be completed until information in the non-optional fields is provided. Certain additional personal information may be requested by Taurus.",
    "Policy objective":"Policy objective",

    "Taurus respects your privacy and assures you that any information provided by you to Taurus is protected and will be dealt with according to this Policy and the applicable laws. To avail the services offered on https://taurus.cash (hereinafter singly referred as the 'Website' ) , you may have to provide certain information to us. This Policy provides the procedure followed by Taurus to gather, uses, store, discloses and manages users' personal data. Taurus only collects personal data for the purpose of verifying user accounts, maintaining the accounts of the users, completing transactions of the users and for analyzing user behavior and requirements.":
    "Taurus respects your privacy and assures you that any information provided by you to Taurus is protected and will be dealt with according to this Policy and the applicable laws. To avail the services offered on https://taurus.cash (hereinafter singly referred as the 'Website' ) , you may have to provide certain information to us. This Policy provides the procedure followed by Taurus to gather, uses, store, discloses and manages users' personal data. Taurus only collects personal data for the purpose of verifying user accounts, maintaining the accounts of the users, completing transactions of the users and for analyzing user behavior and requirements.",

    "What is included in personal data?":"What is included in personal data?",
    "User's personal data includes the following types of personal data or information":"User's personal data includes the following types of personal data or information",
    
    "Sensitive Personal Data":"Sensitive Personal Data",
   "Account password Financial information such as Bank account or credit card or debit card or other payment instrument details":"Account password Financial information such as Bank account or credit card or debit card or other payment instrument details",


    "Other Personal Information": "Other Personal Information",
    "Date of birth":"Date of birth",
    "Telephone number":"Telephone number",
    "The IP address of your computer, browser type and language.":"The IP address of your computer, browser type and language.",
    "The date and the time during which you accessed the site.":"The date and the time during which you accessed the site.",
    "The address of the website which you may have used to link to any Website":"The address of the website which you may have used to link to any Website",
    "Your photograph for testimonials and other promotions.":"Your photograph for testimonials and other promotions.",
    "Such other information that is defined as sensitive personal data or information in law.":"Such other information that is defined as sensitive personal data or information in law.",
    "You shall be entitled to update the information provided by you by contacting us at bethanywhite1172@gmail.com. Your information shall be updated subject to reasonable checks and confirmation by Taurus.":
    "You shall be entitled to update the information provided by you by contacting us at bethanywhite1172@gmail.com. Your information shall be updated subject to reasonable checks and confirmation by Taurus.",


    "User Consents":"User Consents",

    "Consent for use of Sensitive Personal Data and Other Personal Information":"Consent for use of Sensitive Personal Data and Other Personal Information",
    
    "All users of Taurus consent to the use of Sensitive Personal Data and Other Personal Information for the purpose stated in this policy. We restrict access of personal information to our employees, contractors and agents and only allow access to those who need to know that information in order to process it on our behalf.":
    "All users of Taurus consent to the use of Sensitive Personal Data and Other Personal Information for the purpose stated in this policy. We restrict access of personal information to our employees, contractors and agents and only allow access to those who need to know that information in order to process it on our behalf.",

    "Taurus may also use software applications for website traffic analysis and to gather statistics, used for advertising and for determining the efficacy and popularity of Taurus among others.":
    "Taurus may also use software applications for website traffic analysis and to gather statistics, used for advertising and for determining the efficacy and popularity of Taurus among others.",

    "Consent to use of Cookies": "Consent to use of Cookies",

    "What is a Cookie: A cookie is a small text file that uniquely identifies your browser. You understand that when you visit the Website, cookies may be left in your computer. The cookies assigned by the servers of Taurus may be used to personalize your experience on the Website. Additionally, cookies may also be used for authentication, game management, data analysis and security purposes.":
    "What is a Cookie: A cookie is a small text file that uniquely identifies your browser. You understand that when you visit the Website, cookies may be left in your computer. The cookies assigned by the servers of Taurus may be used to personalize your experience on the Website. Additionally, cookies may also be used for authentication, game management, data analysis and security purposes.",

    "Data Security":"Data Security",

    "We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data. Taurus has a comprehensive information security program and information security policies, which contain managerial, technical, operational and physical security control measures adopted by Taurus for the protection of Sensitive Personal Date and Other Personal Information.":
    "We take appropriate security measures to protect against unauthorized access to or unauthorized alteration, disclosure or destruction of data. These include internal reviews of our data collection, storage and processing practices and security measures, including appropriate encryption and physical security measures to guard against unauthorized access to systems where we store personal data. Taurus has a comprehensive information security program and information security policies, which contain managerial, technical, operational and physical security control measures adopted by Taurus for the protection of Sensitive Personal Date and Other Personal Information.",


    "Information gathered by Taurus is stored securely using several information security applications including firewalls. However, security is always relative and Taurus cannot guarantee that its security measures are absolute and cannot be breached. Data which is transmitted over the Internet is inherently exposed to security risks or threats. For instance, information transmitted via chat or email can be compromised and used by others. Therefore, Taurus cannot guarantee any security for such information in the course of transmission through the internet infrastructure or any unsolicited disclosures made by any user availing the services of the Website.":
    "Information gathered by Taurus is stored securely using several information security applications including firewalls. However, security is always relative and Taurus cannot guarantee that its security measures are absolute and cannot be breached. Data which is transmitted over the Internet is inherently exposed to security risks or threats. For instance, information transmitted via chat or email can be compromised and used by others. Therefore, Taurus cannot guarantee any security for such information in the course of transmission through the internet infrastructure or any unsolicited disclosures made by any user availing the services of the Website.",

    "When you register with Taurus, your account is protected by means of login information which includes a username and a password that is known only to you. Therefore, you should not provide your personal information to anyone whosoever and breach hereof constitutes violation of this Policy and can also result in closure of account in certain cases.":
    "When you register with Taurus, your account is protected by means of login information which includes a username and a password that is known only to you. Therefore, you should not provide your personal information to anyone whosoever and breach hereof constitutes violation of this Policy and can also result in closure of account in certain cases.",

    "Taurus has a policy of not sharing any personally identifiable information with anyone other than entities specifically authorized by Taurus which may include advertisers and sponsors of Taurus. However,Taurus may use your name, photo, Login ID and the state from where you are participating when announcing the results of any contests run on the Website. Such contests are further governed by terms and conditions which shall be available on the Websites as and when such a contest is run on any Website.Taurus conducts periodic analysis and survey of the traffic to https://taurus.cash Taurus官网域名 for market research and advertising purposes. Taurus reserves the right to share your registration information with Taurus appointed market research and advertising companies or firms from time to time for the said purposes. Taurus may also use cumulative non-personal information for auditing and analysis purposes with the aim of improving its services.":
    "Taurus has a policy of not sharing any personally identifiable information with anyone other than entities specifically authorized by Taurus which may include advertisers and sponsors of Taurus. However,Taurus may use your name, photo, Login ID and the state from where you are participating when announcing the results of any contests run on the Website. Such contests are further governed by terms and conditions which shall be available on the Websites as and when such a contest is run on any Website.Taurus conducts periodic analysis and survey of the traffic to https://taurus.cash for market research and advertising purposes. Taurus reserves the right to share your registration information with Taurus appointed market research and advertising companies or firms from time to time for the said purposes. Taurus may also use cumulative non-personal information for auditing and analysis purposes with the aim of improving its services.",

    "Data Retention policy":"Data Retention policy",
    "Taurus shall retain the information provided by the users for such period of time as is required to fulfil the purposes for which such information is collected, as outlined in this Privacy Policy, subject to such longer time periods of retention as may be required under any applicable laws.":"Taurus shall retain the information provided by the users for such period of time as is required to fulfil the purposes for which such information is collected, as outlined in this Privacy Policy, subject to such longer time periods of retention as may be required under any applicable laws.",
    
    "Exclusions":"Exclusions",

    "Taurus may share Sensitive Personal Data and Other Personal Information if sharing of such information is necessary":
    "Taurus may share Sensitive Personal Data and Other Personal Information if sharing of such information is necessary",
    "to comply with legal processes or governmental request":"to comply with legal processes or governmental request",
    "to enforce the Terms of Service and this Privacy Policy":"to enforce the Terms of Service and this Privacy Policy",
    "for prevention of fraud":"for prevention of fraud",
    "for issues involving information security, or":"for issues involving information security, or",
    "to protect: your rights; rights of Taurus; and rights of the general public.":"to protect: your rights; rights of Taurus; and rights of the general public.",

    "Limitation of Liability":"Limitation of Liability",

    "Taurus confirms that this Privacy Policy is only a description of its operation regarding user information. This Policy is not intended to and does not create any legal rights in your favour or in the favour of any other person. Taurus reserves the right to change this Policy at any time without giving you prior notice. The liability of Taurus shall be limited to removal of Sensitive Personal Data from the system of the Websites and removal of personally identifiable elements of the Other Personal Information. Notwithstanding anything to the contrary contained in this Policy and elsewhere, the aggregate liability of Taurus for all claims for damages pursuant to provisions of services on the Website, including claims in respect to the violation of this Policy, shall be limited to the aggregate maximum amount of liability as provided in the Terms of Service.":
    "Taurus confirms that this Privacy Policy is only a description of its operation regarding user information. This Policy is not intended to and does not create any legal rights in your favour or in the favour of any other person. Taurus reserves the right to change this Policy at any time without giving you prior notice. The liability of Taurus shall be limited to removal of Sensitive Personal Data from the system of the Websites and removal of personally identifiable elements of the Other Personal Information. Notwithstanding anything to the contrary contained in this Policy and elsewhere, the aggregate liability of Taurus for all claims for damages pursuant to provisions of services on the Website, including claims in respect to the violation of this Policy, shall be limited to the aggregate maximum amount of liability as provided in the Terms of Service.",




    //PC-withdraw-history.vue

    "Withdrawal Amount":"Withdrawal Amount",	
    "Before Withdrawal":"Before Withdrawal",
    "Available Balance":"Available Balance",


    //PC-home.vue
    "Welcome bonus":"Welcome bonus",
    "Welcome to Taurus, and receive ₹20!":"टॉरस में आपका स्वागत है, और ₹20 पाएं!",	


    //PC-terms-of-use.vue
    "Introduction":"Introduction",
    "Your use of the products and/or services on the Portal (hereinafter referred to as 'Services') currently offered or to be offered in future by Taurus Limited (hereinafter referred to as 'Taurus'), its subsidiaries, affiliates, licensors, associates and partners through the Portal( which is https://taurus.cash, hereinafter referred as 'Website'), are all subject to and governed by these Terms of Service.":
    "Your use of the products and/or services on the Portal (hereinafter referred to as 'Services') currently offered or to be offered in future by Taurus Limited (hereinafter referred to as 'Taurus'), its subsidiaries, affiliates, licensors, associates and partners through the Portal( which is https://taurus.cash, hereinafter referred as 'Website' ), are all subject to and governed by these Terms of Service.",


    "You understand that the Terms will be binding on you. You agree that Services offered on the website can be accessed only in accordance with the Terms and you shall be responsible to comply with the Terms at all times. You are responsible to be aware of and agree to abide by the Terms as published and periodically amended or modified by Taurus.":
    "You understand that the Terms will be binding on you. You agree that Services offered on the website can be accessed only in accordance with the Terms and you shall be responsible to comply with the Terms at all times. You are responsible to be aware of and agree to abide by the Terms as published and periodically amended or modified by Taurus.",

    "If any of the Terms are determined to be unlawful, invalid, void or unenforceable for any reason by any judicial or quasi - judicial body in India, it will not affect the validity and enforceability of the remaining Terms. Our failure or delay to act or exercise any right or remedy with respect to a breach of any of the Terms by you shall not be construed as a waiver of our right to act with respect to the said breach or any prior, concurrent, subsequent or similar breaches.":
    "If any of the Terms are determined to be unlawful, invalid, void or unenforceable for any reason by any judicial or quasi - judicial body in India, it will not affect the validity and enforceability of the remaining Terms. Our failure or delay to act or exercise any right or remedy with respect to a breach of any of the Terms by you shall not be construed as a waiver of our right to act with respect to the said breach or any prior, concurrent, subsequent or similar breaches.",

    "Applicability":"Applicability",
    "Before registering with us, you should carefully read and review these terms provided on https://taurus.cash website which are applicable to all services on the portal and also the product-specific conditions and rules applicable to specific offerings.":
    "Before registering with us, you should carefully read and review these terms provided on https://taurus.cash website which are applicable to all services on the portal and also the product-specific conditions and rules applicable to specific offerings.",
    
    "Game Promotion Services":"Game Promotion Services",

    "All game promotion and practice organized on the Websites are collectively referred as 'Game Promotion'. We reserve the right to decide commission percentage for game promoters. We also reserve the right to stop any game promotions with forbidden and illegal behaviors.":
    "All game promotion and practice organized on the Websites are collectively referred as 'Game Promotion'. We reserve the right to decide commission percentage for game promoters. We also reserve the right to stop any game promotions with forbidden and illegal behaviors.",
    
    "Game promoter representations":"Game promoter representations",
    "Any information provided by you to us, whether at the stage of registration or during anytime subsequently, should be complete and truthful.":
    "Any information provided by you to us, whether at the stage of registration or during anytime subsequently, should be complete and truthful.",

    "You represent that you are 18 years of age or older to participate in any game promotion and are also otherwise competent to enter into transactions with other users and us. With full knowledge of the facts and circumstances surrounding promotions, you are voluntarily participating in the promotions and assume all responsibility for and risk resulting from your participation.":
    "You represent that you are 18 years of age or older to participate in any game promotion and are also otherwise competent to enter into transactions with other users and us. With full knowledge of the facts and circumstances surrounding promotions, you are voluntarily participating in the promotions and assume all responsibility for and risk resulting from your participation.",
    
    "You represent that you have the experience and the requisite skills required to participate in the promotions and that you are aware of any physical or mental condition that would impair your capability to fully participate in the promotions. You further acknowledge that you are solely responsible for any consequence resulting from you participating in the promotions or being associated with the promotions or around the promotions.":
    "You represent that you have the experience and the requisite skills required to participate in the promotions and that you are aware of any physical or mental condition that would impair your capability to fully participate in the promotions. You further acknowledge that you are solely responsible for any consequence resulting from you participating in the promotions or being associated with the promotions or around the promotions.",

	
    "You shall not hold us responsible for not being able to pay you commission for which you may be eligible to participate. This includes but is not limited to situations where you are unable to share your referral link to more users and you are not unable to activate users to recharge in the game.":
    "You shall not hold us responsible for not being able to pay you commission for which you may be eligible to participate. This includes but is not limited to situations where you are unable to share your referral link to more users and you are not unable to activate users to recharge in the game.",

    "Promoter Account Creation & Operation":"Promoter Account Creation & Operation",

    "By completing the online registration process on the website, you confirm your acceptance of the Terms.":"By completing the online registration process on the website, you confirm your acceptance of the Terms.",

	"By registering on our website, you agree to receive all communication from us including promotional messages, voice call, Email and Push Notifications. You may withdraw your consent by sending an email to bethanywhite1172@gmail.com as the case may be.":"By registering on our website, you agree to receive all communication from us including promotional messages, voice call, Email and Push Notifications. You may withdraw your consent by sending an email to bethanywhite1172@gmail.com as the case may be.",

    "During the registration process, you will be required to choose a login name and a password in addition to providing some other information which may not be mandatory. Additionally, you may be required to give further personal information for your user account verification and/or for adding cash to your user account. You must give us the correct details in all fields requiring your personal information, including, without limitation, your name, postal address, email address, telephone number(s) etc. You undertake that you will update this information and keep it current.":
    "During the registration process, you will be required to choose a login name and a password in addition to providing some other information which may not be mandatory. Additionally, you may be required to give further personal information for your user account verification and/or for adding cash to your user account. You must give us the correct details in all fields requiring your personal information, including, without limitation, your name, postal address, email address, telephone number(s) etc. You undertake that you will update this information and keep it current.",

	 
    "You acknowledge that we may, at any time, required to verify the correctness of this information and in order to do so may require additional documentary proof from you, failing which we reserve the right to suspend or terminate your registration on the Website.":
    "You acknowledge that we may, at any time, required to verify the correctness of this information and in order to do so may require additional documentary proof from you, failing which we reserve the right to suspend or terminate your registration on the Website.",

    "Any information provided by you to us should be complete and truthful to the best of your knowledge. We are not obliged to cross check or verify information provided by you and we will not take any responsibility for any outcome or consequence as a result of you providing incorrect information or concealing any relevant information from us.":
    "Any information provided by you to us should be complete and truthful to the best of your knowledge. We are not obliged to cross check or verify information provided by you and we will not take any responsibility for any outcome or consequence as a result of you providing incorrect information or concealing any relevant information from us.",

	
    "You understand that it is your responsibility to protect the information you provide on the Websites including but not limited to your Username, Password, Email address, Contact Details and Mobile number. We will not ask for your user account login password which is only to be entered at the time of login. At no other time should you provide your user account information to any user logged in on the Websites or elsewhere. You undertake that you will not allow / login and then allow, any other person to play from your user account using your username. You specifically understand and agree that we will not incur any liability for information provided by you to anyone which may result in your user account on the Websites being exposed or misused by any other person.":
    "You understand that it is your responsibility to protect the information you provide on the Websites including but not limited to your Username, Password, Email address, Contact Details and Mobile number. We will not ask for your user account login password which is only to be entered at the time of login. At no other time should you provide your user account information to any user logged in on the Websites or elsewhere. You undertake that you will not allow / login and then allow, any other person to play from your user account using your username. You specifically understand and agree that we will not incur any liability for information provided by you to anyone which may result in your user account on the Websites being exposed or misused by any other person.",

    "User restrictions":"User restrictions",

    "Anti-Cheating and Anti-Collusion":"Anti-Cheating and Anti-Collusion",

    "You undertake the responsibilities in the promotions in which you have registered/joined. You shall not add unauthorized components, create or use cheats, exploits, bots, hacks or any other third-party software designed to modify the Websites or use any third-party software that intercepts, mines or otherwise collects information from or through the Websites or through any Services. Any attempt to employ any such external assistance is strictly prohibited.":
    "You undertake the responsibilities in the promotions in which you have registered/joined. You shall not add unauthorized components, create or use cheats, exploits, bots, hacks or any other third-party software designed to modify the Websites or use any third-party software that intercepts, mines or otherwise collects information from or through the Websites or through any Services. Any attempt to employ any such external assistance is strictly prohibited.",

    "Formation of teams for the purpose of collusion between you and any other user(s) for participating in the promotions organized on the Websites or any other form of cheating is strictly prohibited.":
    "Formation of teams for the purpose of collusion between you and any other user(s) for participating in the promotions organized on the Websites or any other form of cheating is strictly prohibited.",


    "When collusion or cheating is detected on https://taurus.cash, we shall take further appropriate action against offending users in terms hereof including banning access to the Portal and any or all Services.":
    "When collusion or cheating is detected on https://taurus.cash, we shall take further appropriate action against offending users in terms hereof including banning access to the Portal and any or all Services.",

    "Money Laundering":"Money Laundering",

    
    "You are prohibited from doing any activity on the Website that may be construed as money laundering, including, without limitation, attempting to withdraw cash from unutilized cash added through credit cards or deliberately losing money to a certain player(s).":
    "You are prohibited from doing any activity on the Website that may be construed as money laundering, including, without limitation, attempting to withdraw cash from unutilized cash added through credit cards or deliberately losing money to a certain player(s).",
    
    "Anti-SPAMMING":"Anti-SPAMMING",

    "Sending SPAM emails or any other form of unsolicited communication for obtaining registrations on the Website to benefit from any of our promotional program or for any other purpose is strictly prohibited.":
    "Sending SPAM emails or any other form of unsolicited communication for obtaining registrations on the Website to benefit from any of our promotional program or for any other purpose is strictly prohibited.",

    "You shall not purchase, sell, trade, rent, lease, license, grant a security interest in, or transfer your user account, Content, currency, points, standings, rankings, ratings, or any other attributes appearing in, originating from or associated with the website.":
    "You shall not purchase, sell, trade, rent, lease, license, grant a security interest in, or transfer your user account, Content, currency, points, standings, rankings, ratings, or any other attributes appearing in, originating from or associated with the website.",

    "Any form of fraudulent activity including, attempting to use or using any other person's credit card(s), debit cards, net-banking usernames, passwords, authorization codes, prepaid cash cards, mobile phones for adding cash to your user account is strictly prohibited.":
    "Any form of fraudulent activity including, attempting to use or using any other person's credit card(s), debit cards, net-banking usernames, passwords, authorization codes, prepaid cash cards, mobile phones for adding cash to your user account is strictly prohibited.",

    "Winnings, bonuses and prizes are unique to the player and are non-transferable. In the event you attempt to transfer any winnings, bonuses or prizes, these will be forfeited.":
    "Winnings, bonuses and prizes are unique to the player and are non-transferable. In the event you attempt to transfer any winnings, bonuses or prizes, these will be forfeited.",
    
    "If you are an officer, director, employee, consultant or agent of Taurus or a relative of such persons ('Associated Person'), you are not permitted to play either directly or indirectly, any Games which entitle you to any prize on the Portal, other than in the course of your engagement with us. For these purposes, the term 'relative' shall include spouse and financially dependent parents and, children.":
    "If you are an officer, director, employee, consultant or agent of Taurus or a relative of such persons ('Associated Person'), you are not permitted to play either directly or indirectly, any Games which entitle you to any prize on the Portal, other than in the course of your engagement with us. For these purposes, the term 'relative' shall include spouse and financially dependent parents and, children.",

    "Content":"Content",
    "All content and material on the website including but not limited to information, images, marks, logos, designs, pictures, graphics, text content, hyperlinks, multimedia clips, animation, games and software (collectively referred to as 'Content'), whether or not belonging to Taurus, are protected by applicable intellectual property laws.":
    "All content and material on the website including but not limited to information, images, marks, logos, designs, pictures, graphics, text content, hyperlinks, multimedia clips, animation, games and software (collectively referred to as 'Content'), whether or not belonging to Taurus, are protected by applicable intellectual property laws. ",

    "The Portal may contain information about or hyperlinks to third parties. In such a cases, we are not responsible in any manner and do not extend any express or implied warranty to the accuracy, integrity or quality of the content belonging to such third party websites or apps. If you rely on any third party Content posted on any website or app which does not belong to Taurus, you may do so solely at your own risk and liability.":
    "The Portal may contain information about or hyperlinks to third parties. In such a cases, we are not responsible in any manner and do not extend any express or implied warranty to the accuracy, integrity or quality of the content belonging to such third party websites or apps. If you rely on any third party Content posted on any website or app which does not belong to Taurus, you may do so solely at your own risk and liability.",

    "If you visit any third party website or app through a third party Content posted on the website, you will be subject to terms and conditions applicable to such third party website or app. We neither control nor are responsible for content on such third-party websites or apps. The fact of a link existing on our website to a third-party website or app is not an endorsement of that website or app by us.":
    "If you visit any third party website or app through a third party Content posted on the website, you will be subject to terms and conditions applicable to such third party website or app. We neither control nor are responsible for content on such third-party websites or apps. The fact of a link existing on our website to a third-party website or app is not an endorsement of that website or app by us.",
    
    "Complaints, Grievances & disputes":"Complaints, Grievances & disputes",


    "If you have a complaint, you should in the first instance contact the customer support team at bethanywhite1172@gmail.com, as the case may be or write to us following the procedure given in the respective Contact Us section on the relevant Websites. Complaints should be made as soon as possible after circumstances arise that cause you to have a complaint.":
    "If you have a complaint, you should in the first instance contact the customer support team at bethanywhite1172@gmail.com, as the case may be or write to us following the procedure given in the respective Contact Us section on the relevant Websites. Complaints should be made as soon as possible after circumstances arise that cause you to have a complaint.",

    "You accept that any complaints and disputes are and remain confidential both whilst a resolution is sought and afterwards. You agree that you shall not disclose the existence, nature or any detail of any complaint or dispute to any third party.":
    "You accept that any complaints and disputes are and remain confidential both whilst a resolution is sought and afterwards. You agree that you shall not disclose the existence, nature or any detail of any complaint or dispute to any third party.",

    "Taurus shall make efforts to resolve complaints within reasonable time. Our decision on complaints shall be final and binding on you.":
    "Taurus shall make efforts to resolve complaints within reasonable time. Our decision on complaints shall be final and binding on you.",

    "Modifications and alterations":"Modifications and alterations",

    "We may alter or modify the Terms at any time without giving prior notice to you. We may choose to notify of some changes in the Terms either by email or by displaying a message on the website; however, our notification of any change shall not waive your obligation to keep yourself updated about the changes in the Terms.":
    "We may alter or modify the Terms at any time without giving prior notice to you. We may choose to notify of some changes in the Terms either by email or by displaying a message on the website; however, our notification of any change shall not waive your obligation to keep yourself updated about the changes in the Terms.",
    

    "keep me signed in":"keep me signed in",



    //PC-discounts-store.vue
    "You don't have permission":"You don't have permission",
    "Discounted Chips":"Discounted Chips",


    //PC-my-feferrals.vue
    "Recent search":"Recent search",
    "Empty":"Empty",
    "When you reach the diamond level,":"When you reach the diamond level,",
    "you can unlock it":"You can unlock it",

    //PC-send.vue
    "Related information cannot be empty. ID is invalid":"Related information cannot be empty. ID is invalid",
	"Amount cannot be empty":"Amount cannot be empty",
    "Insufficient balance":"Insufficient balance",
    "You can't recharge for youyself.":"You can't recharge for youyself.",
    "Enter chips amount":"Enter chips amount",
    "Send to a specific user":"Send to a specific user",
    "Pending":"Pending",

    //PC-sign-in.vue
    'Please enter 11-digit Chinese phone number':'Please enter 11-digit Chinese phone number',
    'Please enter 10-digit Indian phone number':'Please enter 10-digit Indian phone number',
    'Please enter 4-digit OTP':'Please enter 4-digit OTP',

    //PC-After-header.vue
    "How it works":"How it works",


    //index.vue
    "Please enter a name with less than 20 characters":"Please enter a name with less than 20 characters",
    "Please check this box if you want to proceed":"Please check this box if you want to proceed.",
    "App could not be downloaded at this time":"App could not be downloaded at this time",
    
    //PC-replace.vue
    "No Data":"No Data",
    "There is no commission record yet.":"There is no commission record yet.",
    "Want to get commission, go share the link now!":"Want to get commission, go share the link now!",
    "Share now":"Share now",

    //PC-tip-btn.vue
    "refresh":"refresh",

    //trade.js
    'Please reach a higher division':'Please reach a higher division',
    'Failed to generate order':'Failed to generate order',
    'Recharge succeeded':'Recharge succeeded',
    'The withdrawal method is not supported':'The withdrawal method is not supported',

    //user.js
    'OTP and phone number is required for your account':'OTP and phone number is required for your account',
    "Wrong OTP":"Wrong OTP",
    "Server exception":"Server exception",
    "You have not signed up yet":"You have not signed up yet",
    "Phone number is required for your account":"Phone number is required for your account",

    //context.js
    "Share successful":"Share successful",

    //PC-replace.vue
    "No commission yet,go share the link now":"No commission yet,go share the link now",

    //PC-referrals.vue
    "Affiliate Details":"Affiliate Details",
    "When you reach the platinum division,":"When you reach the platinum division,",
    "When you reach the gold division,":"When you reach the gold division,",
    "When you reach the silver division,":"When you reach the silver division,",
    "When you reach the diamond division,":"When you reach the diamond division,",
    "you can unlock it":"you can unlock it",
    "Silver Division":"Silver Division",
    "Gold Division":"Gold Division",
    "Platinum Division":"Platinum Division",
    "Diamond Division":"Diamond Division",
    "Last Sign-in":"Last Sign-in",
    "Affiliates Details":"Affiliates Details",

    //PC-my-affilialtes-commission.vue
    "New Referral":"New Referral",
    "New Affiliates":"New Affiliates",

    "Diamond Unlock":"Diamond Unlock",
     "Platinum unlock":"Platinum unlock",
     "Gold unlock":"Gold unlock",
     "Silver unlock":"Silver unlock",

     "Payment":"Payment",
     "Have you finished your payment?":"Have you finished your payment?",
     "Next time":"Next time",
     "Done":"Done",

     " invitation,":" invitation,",
     "per person":"per person",
     " recharging in the game, up to ":" recharging in the game, up to ",
     " 30%":" 30%",

     "Start Date":"Start Date",
     "End Date":"End Date",

     "Purchased Chips  in Total":"Purchased Chips  in Total",
     "Get commission from": "Get commission from",

     "Withdrawal Method":"Withdrawal Method",
     "Legend Story on Taurus Promoters":"Legend Story on Taurus Promoters",
     "Story":"Story",
     "Business Cooperation:":"Business Cooperation:",
     "Follow Us":"Follow Us",
    
};
